import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import BannerContent from "../BannerContent/BannerContent";
import BannerSearch from "../BannerSearch/BannerSearch";
import './assets/styles/_index.scss';
const { ImageModule } = require("@starberry/gatsby-theme-utils/Modules/ImageModule")

const Banner = (props) => {
    var imagename = "page.banner_section_banner_image.landing_banner_image";

    let processedImages = JSON.stringify({});
    if (props?.imagetransforms?.banner_section_banner_image_Transforms) {
        processedImages = props?.imagetransforms?.banner_section_banner_image_Transforms;
    }
    return (
        <section className={`banner d-flex align-items-end ${props.bannerClass}`}>
            <ImageModule ImageSrc={props?.image} altText={props?.image?.alternativeText} imagetransforms={processedImages} renderer="srcSet" imagename={imagename} strapi_id={props?.id} classNames="img-fluid banner-img" />
            <div className="overlay-bg"></div>
            <Container className="banner-search-container">
                <Row>
                    <Col xl={7}>
                        {!props.show_search ? <BannerContent title={props.title} {...props}/> :
                        <BannerSearch {...props}/> }
                    </Col>
                </Row>
            </Container>
        </section>
    )
}

export default Banner