import React from "react";
import loadable from "@loadable/component";
import { graphql } from 'gatsby'
import Layout from "../components/layout";
import SEO from "../components/seo";
import ReviewsImg from "../images/reviews_img.png";
import { PageLinks } from "../common/site/page-static-links";

import Banner from "../components/Banner/Banner";
const TileBlock = loadable(() => import("../components/TileBlock/TileBlock"));
const StatsModule = loadable(() => import("../components/StatsModule/StatsModule"));
const ServicesOffer = loadable(() => import("../components/ServicesOffer/ServicesOffer"));
const ValuationModule = loadable(() => import("../components/ValuationModule/ValuationModule"));
const PropertySlider = loadable(() => import("../components/PropertySlider/PropertySlider"));
const ReviewsModule = loadable(() => import("../components/ReviewsModule/ReviewsModule"));
const NewsInsightsSlider = loadable(() => import("../components/NewsInsightsSlider/NewsInsightsSlider"));
const TeamSlider = loadable(() => import("../components/TeamSlider/TeamSlider"));

const LandingPageTemplate = ({ data }, props) => {
  const PageData = data?.strapiPage
  return (
    <Layout popularSearch={PageData?.select_popular_search?.title}>

      {PageData.banner && <Banner title={PageData.banner?.banner_title} {...PageData.banner} id={PageData.strapi_id} imagetransforms={PageData.imagetransforms} />}
      {PageData?.Add_Page_Modules?.length > 0 && PageData.Add_Page_Modules?.map((module, index) => {
        return (
          <>
            {module.strapi_component === "page-modules.image-and-content" && module.layout === "default" && <TileBlock {...module} id={PageData.strapi_id} imagetransforms={PageData.imagetransforms} />}

            {module.strapi_component === "page-modules.image-and-content" && module.layout === "fullwidth" && <ValuationModule {...module} id={PageData.strapi_id} imagetransforms={PageData.imagetransforms}/>}

            {module.strapi_component === "page-modules.text-module" && module.add_services.length > 0 && <ServicesOffer {...module} id={PageData.strapi_id} imagetransforms={PageData.imagetransforms}/>}

            {module.strapi_component === "page-modules.global-module" && module.select_module === "featured_properties" && <PropertySlider /> }

            {module.strapi_component === "page-modules.global-module" && module.select_module === "google_reviews_slider" && <ReviewsModule reviewsImg={ReviewsImg} /> }

            {module.strapi_component === "page-modules.global-module" && module.select_module === "latest_news_slider" && <NewsInsightsSlider /> }

            {module.strapi_component === "page-modules.stats-module" && <StatsModule {...module} /> }
            
            {module.strapi_component === "page-modules.team-slider" && <TeamSlider {...module} />}
          </>
        )
      })}
    </Layout>
  )
}


export const Head = ({ data }, props) => {
    const PageData = data?.strapiPage
    var about_path = '/' + PageLinks.about + '/'
    var schema = false
    if (about_path === (typeof window !== 'undefined' ? window.location.pathname : '')) {
      schema = true
    }
    var ldJson = {
      "@context": "https://schema.org",
      "@type": "RealEstateAgent",
      "url": process.env.GATSBY_SITE_URL + '/' + PageLinks.about + '/',
      "name": process.env.GATSBY_SITE_NAME,
      "image": PageData.banner?.image?.url,
      "logo": process.env.GATSBY_SITE_URL + `/images/logo.png`
    };
  
    return (
      <SEO title={PageData?.seo?.metaTitle ? PageData?.seo?.metaTitle : PageData?.title} description={PageData?.seo?.metaDescription ? PageData?.seo?.metaDescription : PageData?.title}>
        {schema &&
          <script
            type="application/ld+json"
            dangerouslySetInnerHTML={{ __html: JSON.stringify(ldJson) }}
          />
        }
      </SEO>
    )
  }

export default LandingPageTemplate



export const query = graphql`
  query ($page_id: String) {
    strapiPage(id: {eq: $page_id}) {
        ...PageFragment
        Add_Page_Modules {
            ... on STRAPI__COMPONENT_PAGE_MODULES_GLOBAL_MODULE {
              ...GlobalModuleFragment
            }
            ... on STRAPI__COMPONENT_PAGE_MODULES_IMAGE_AND_CONTENT {
              ...ImageAndContentFragment
            }
            ... on STRAPI__COMPONENT_PAGE_MODULES_TEXT_MODULE {
              ...TextModuleFragment
            }
            ... on STRAPI__COMPONENT_PAGE_MODULES_STATS_MODULE {
              ...StatsModuleFragment
            }
            ... on STRAPI__COMPONENT_PAGE_MODULES_TEAM_SLIDER {
                ...TeamSliderModuleFragment
            }
        }
    }
  }
`